import {acceptHMRUpdate, defineStore} from "pinia";
import {useSpecials} from "~/store/specials";
import {useClinics} from "~/store/clinics";
import {useRegion} from "~/store/app/region";

export const useSearch = defineStore("search", {
    state: () => {
        return {
            searchText: "",
            searchSpecial: null,
            searchClinic: null,
        };
    },
    getters: {
        listClinicsSearch: (state) => {
            const regionStore = useRegion();
            const clinicsStore = useClinics();

            const listClinics = regionStore.listClinicsRegion().map(clinicId =>
                clinicsStore.getClinicById(clinicId)
            );

            listClinics.push({
                id: null,
                attributes: {address: "Все клиники"},
            });

            state.searchClinic = listClinics[0];
            return listClinics.reverse();
        },
        listSpecialsSearch: (state) => {
            const specialsStore = useSpecials();
            const listSpecials = specialsStore.filterListSpecials;

            if (listSpecials.findIndex((special) => special.attributes.title === "Все специализации") === -1)
                listSpecials.push({
                    id: null,
                    attributes: {title: "Все специализации"},
                });

            state.searchSpecial = listSpecials[0];
            return listSpecials;
        }
    },
    actions: {
        clearSearch() {
            const specialsStore = useSpecials();
            this.searchText = "";
            this.searchSpecial = specialsStore.filterListSpecials[0];
            this.searchClinic = this.listClinicsSearch[0];
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSearch, import.meta.hot));
}
